











































import axios, { AxiosRequestConfig } from 'axios';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { Amount, Wallet, WalletFlags } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';

import { baConfig } from '../../../config';
import { ApiSvcWalletResponseDTO, WalletsApi } from '../../../generated/api-svc';
import GroupWalletItem from './GroupWalletItem.vue';

type WalletDTO = Wallet & {
  root: boolean;
  leaf: boolean;
  expanded: boolean;
  children: WalletDTO[];
};

@Component({
  components: {
    GroupWalletItem,
    UiButton,
  },
})
export default class WalletList extends BaseVue {
  @Prop({ required: true })
  public readonly filter!: string;

  @Prop({ default: false })
  public readonly hideZeroBalance!: boolean;

  @Prop({ default: null })
  public readonly sortMode!: { value: string; label: string };

  wallets: Wallet[] = [];
  walletBalances: Wallet[] = [];
  groupWallets: WalletDTO[] = [];

  isLoading = 0;
  isLoadingBalance = 0;
  filteredGroupWallets: WalletDTO[] = [];
  allowLoadBalance = false;
  processingBalances = false;
  currentPage = 1;
  itemsPerPage = 10;

  mounted() {
    // this.refresh();
    this.$root.$on('refresh-wallets', () => {
      this.refresh();
    });
  }

  getBalance(id: string) {
    const wallet = this.wallets.find((wallet: Wallet) => wallet.id === id);
    if (!wallet) {
      return;
    }
    return wallet.balance;
  }

  async refresh() {
    this.currentPage = 1;
    this.processingBalances = true;
    this.allowLoadBalance = true;
    this.isLoadingBalance++;
    this.isLoading++;
    try {
      const apiSvc = new WalletsApi(undefined, baConfig.getFriendlyApiUrl());
      const options: AxiosRequestConfig = { withCredentials: true };
      const resp = await apiSvc.getWallets(
        this.$store.state.currentOrg.id,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        true,
        undefined,
        undefined,
        false,
        options
      );
      const wallets = resp.data.items.map((m) => this.fromWalletDto(m));
      this.wallets = wallets;
    } catch (error) {
      console.error('Error loading wallets:', error);
      this.showErrorSnackbar('Failed to load wallets. Error: ' + error);
    } finally {
      this.processingBalances = false;
      this.isLoadingBalance--;
      this.isLoading--;
    }
  }

  fromWallet(wallet: Wallet) {
    const value = {
      ...wallet,
      balance: wallet.id ? this.getBalance(wallet.id) : undefined,
      root: false,
      leaf: true,
      expanded: false,
      children: [],
    };
    return value;
  }

  fromWalletDto(wallet: ApiSvcWalletResponseDTO): Wallet {
    const value: Wallet & { root: boolean; leaf: boolean; expanded: boolean; children: any } = {
      ...wallet,
      flags: wallet.flags as WalletFlags,
      root: false,
      leaf: true,
      expanded: false,
      children: [],
    };
    return value;
  }

  @Watch('$store.state.isUpdateWallet')
  syncWallets() {
    this.refresh();
  }

  @Watch('filter')
  filterUpdated() {
    this._filteredWallets();
  }

  @Watch('hideZeroBalance')
  hideZeroBalanceUpdated() {
    this._filteredWallets();
  }

  @Watch('sortMode')
  sortModeUpdated() {
    this._filteredWallets();
  }

  @Watch('$store.state.currentOrg.id')
  async orgIdUpdated() {
    this.allowLoadBalance = false;
    // this.refresh();
  }

  private _filteredWallets() {
    this.currentPage = 1;
    let filteredGWallets = [];

    // filter by group wallet name
    if (this.filter.length > 0) {
      filteredGWallets = this.groupWallets.filter((grpWallet) =>
        grpWallet.name?.toLowerCase()?.includes(this.filter.toLowerCase())
      );
    } else {
      filteredGWallets = [...this.groupWallets];
    }

    if (this.hideZeroBalance) {
      filteredGWallets = filteredGWallets.filter((wallet) => wallet.balance?.totalFiatValue?.value !== 0);
    }

    this.filteredGroupWallets = filteredGWallets.sort((a: any, b: any) => {
      if (this.sortMode?.value === 'name-asc') {
        return a.name.localeCompare(b.name);
      }
      if (this.sortMode?.value === 'name-desc') {
        return b.name.localeCompare(a.name);
      }
      if (this.sortMode?.value === 'balance-asc') {
        return a.balance.totalFiatValue.value - b.balance.totalFiatValue.value;
      }
      if (this.sortMode?.value === 'balance-desc') {
        return b.balance.totalFiatValue.value - a.balance.totalFiatValue.value;
      }
      return 0;
    });
  }

  transformToGroup() {
    let groups: WalletDTO[] = [];
    const dtoMap = new Map<string, WalletDTO>();
    for (const wallet of this.wallets) {
      if (wallet.id) {
        const walletDto = this.fromWallet(wallet);
        dtoMap.set(wallet.id, walletDto);
      }
    }

    dtoMap.forEach((walletDto, walletId) => {
      if (walletDto) {
        if (!walletDto.groupId) {
          groups.push({ ...walletDto, root: true });
        } else if (dtoMap.has(walletDto.groupId)) {
          const parent = dtoMap.get(walletDto.groupId);
          if (parent) {
            parent.children.push(walletDto);
            parent.leaf = false;

            const balances = walletDto.balance?.balances ?? [];
            const currentBalances = parent.balance?.balances ?? [];
            let walletBalance = 0;
            if (walletDto.balance?.totalFiatValue?.value?.toString()) {
              walletBalance = walletDto.balance.totalFiatValue.value;
            } else if (walletDto.balance?.totalFiatValue?.displayValue) {
              const value = Number(walletDto.balance.totalFiatValue.displayValue.replaceAll(',', ''));
              walletBalance = value;
            } else {
              walletBalance = balances.reduce((total, cur) => total + (cur?.fiatValue?.value ?? 0), 0);
            }
            const currentBalance = parent.balance?.totalFiatValue?.value ?? 0;
            const totalBalance = walletBalance + currentBalance;

            balances.forEach((balance) => {
              if (!balance || !balance.coin) return;
              const coinBalance = currentBalances.find((b) => b?.coin === balance.coin);
              if (coinBalance) {
                coinBalance.value = (Number(coinBalance.value) + Number(balance.value)).toString();
                coinBalance.displayValue = this.formatCurrency(Number(coinBalance.value));
                const fiatValue = (coinBalance.fiatValue?.value ?? 0) + (balance.fiatValue?.value ?? 0);
                coinBalance.fiatValue = {
                  currency: this.$store.state.currentOrg.baseCurrency,
                  value: fiatValue,
                  displayValue: this.formatCurrency(fiatValue),
                };
              } else {
                currentBalances.push(balance);
              }
            });

            parent.balance = {
              balances: currentBalances,
              totalFiatValue: {
                currency: this.$store.state.currentOrg.baseCurrency,
                value: totalBalance,
                displayValue: this.formatCurrency(totalBalance),
              },
            };

            groups = groups.map((g) => {
              if (g.id !== parent.id) return g;
              return parent;
            });
          }
        }
      }
    });

    this.groupWallets = groups.map((group) => {
      const wallets = group.children.length ? [...group.children] : [group];
      const totalBalance = wallets.reduce((total, cur) => {
        if (cur.balance?.totalFiatValue?.value) {
          return total + cur.balance.totalFiatValue.value;
        }
        if (cur.balance?.totalFiatValue?.displayValue) {
          const value = Number(cur.balance.totalFiatValue.displayValue.replaceAll(',', ''));
          return total + value;
        }
        const balances = cur.balance?.balances ?? [];
        const walletBalance = balances.reduce((total, cur) => total + (cur?.fiatValue?.value ?? 0), 0);
        return total + walletBalance;
      }, 0);
      const totalBalances = wallets.reduce((currentBalances: Amount[], child) => {
        const balances = child.balance?.balances ?? [];
        balances.forEach((balance) => {
          if (!balance || !balance.coin) return;
          const coinBalance = currentBalances.find((b) => b?.coin === balance.coin);
          if (coinBalance) {
            coinBalance.value = (Number(coinBalance.value) + Number(balance.value)).toString();
            coinBalance.displayValue = this.formatCurrency(Number(coinBalance.value));
            const fiatValue = (coinBalance.fiatValue?.value ?? 0) + (balance.fiatValue?.value ?? 0);
            coinBalance.fiatValue = {
              currency: this.$store.state.currentOrg.baseCurrency,
              value: fiatValue,
              displayValue: this.formatCurrency(fiatValue),
            };
          } else {
            currentBalances.push(balance);
          }
        });
        return currentBalances;
      }, []);
      group.balance = {
        balances: totalBalances,
        totalFiatValue: {
          currency: this.$store.state.currentOrg.baseCurrency,
          value: totalBalance,
          displayValue: this.formatCurrency(totalBalance),
        },
      };

      return group;
    });

    this.filteredGroupWallets = [...this.groupWallets];
  }

  get paginatedWallets() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    return this.filteredGroupWallets.slice(startIndex, endIndex);
  }

  get paginationMessage() {
    const totalItems = this.filteredGroupWallets.length;
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    let endIndex = startIndex + this.itemsPerPage;

    endIndex = endIndex > totalItems ? totalItems : endIndex;

    if (totalItems === 0) {
      return 'No wallets to display.';
    }

    return `Currently showing ${startIndex + 1} to ${endIndex} of ${totalItems} wallets.`;
  }

  @Watch('wallets')
  walletUpdated(val: any) {
    this.transformToGroup();
  }

  @Watch('walletBalances')
  walletBalancesUpdated(val: any) {
    this.transformToGroup();
  }
}
