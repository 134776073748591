<template>
  <div v-if="currentOrg" class="tw-relative">
    <tooltip-select class="tw-absolute tw-right-8 tw-top-9 tw-z-10" tip="_dashboard"></tooltip-select>
    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-layout row wrap style="padding: 1em">
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs12>
                <div class="tw-flex tw-flex-row tw-h-12 tw-my-2.5 tw-items-center">
                  <div
                    class="tw-flex tw-w-2/4 tw-items-center tw-py-1.5 tw-mx-2 tw-px-4 tw-rounded tw-border tw-border-neutral-200"
                  >
                    <input
                      placeholder="Search by group wallet name"
                      class="tw-flex-1 tw-outline-none tw-focus:outline-none tw-w-full"
                      data-testId="dashboard-input-search"
                      v-model="searchKeyword"
                    />
                    <i class="material-icons tw-text-gray-500">search</i>
                  </div>
                  <div class="tw-flex tw-w-1/4 tw-items-center">
                    <ui-select2
                      :value="sortMode"
                      :items="sortModes"
                      :placeholder="'Sort By'"
                      keyField="value"
                      labelField="label"
                      class="tw-w-48 tw-ml-1 tw-w-full"
                      @input="onSortChange($event)"
                    >
                    </ui-select2>
                  </div>
                  <div class="tw-flex tw-w-1/4">
                    <ui-checkbox
                      v-model="hideZeroBalance"
                      label="Hide 0 balances"
                      class="tw-ml-4 tw-whitespace-nowrap"
                      data-testId="dashboard-checkbox-fixed"
                    ></ui-checkbox>
                  </div>
                </div>
                <div class="group-wallets tw-py-2 tw-overflow-y-auto tw-px-2 hover:tw-bg-gray-50">
                  <wallet-list
                    :short="true"
                    :filter="searchKeyword"
                    :hideZeroBalance="hideZeroBalance"
                    :sortMode="sortMode"
                  />
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md6 class="tw-px-4 tw-pt-6">
        <announcements></announcements>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Announcements from '@/components/dashboard/accounting/Announcements.vue';
import WalletList from '@/components/dashboard/WalletList.vue';
import TooltipSelect from '@/components/tooltip/TooltipSelect.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import { store } from '@/store';

export default {
  props: [],
  data() {
    return {
      fixed: false,
      title: 'Bitwave',
      searchKeyword: '',
      hideZeroBalance: false,
      sortMode: this.$store.state.sortMode ?? null,
      sortModes: [
        { value: 'name-asc', label: 'Name (A-Z)' },
        { value: 'name-desc', label: 'Name (Z-A)' },
        { value: 'balance-asc', label: 'Balance (Low-High)' },
        { value: 'balance-desc', label: 'Balance (High-Low)' },
      ],
    };
  },
  computed: {
    currentOrg() {
      return this.$store.state.currentOrg;
    },
  },
  components: {
    WalletList,
    Announcements,
    TooltipSelect,
    UiCheckbox,
    UiSelect2,
  },
  methods: {
    onSortChange(value) {
      this.$store.state.sortMode = value;
      this.sortMode = value;
    },
  },
};
</script>
<style type="text/css">
.group-wallets {
  height: calc(100vh - 150px);
}
.group-wallets::-webkit-scrollbar {
  display: none;
}
.group-wallets:hover::-webkit-scrollbar {
  display: block;
}
.group-wallets::-webkit-scrollbar {
  width: 8px;
}
.group-wallets::-webkit-scrollbar-thumb {
  background: rgb(0, 141, 255);
  border-radius: 10px;
}
</style>
