






















































































































import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { Org, Wallet, WalletBalance } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import Chart from '../Chart.vue';

type WalletDTO = Wallet & {
  root: boolean;
  leaf: boolean;
  expanded: boolean;
  children: WalletDTO[];
};

type Balance = {
  coin: string;
  value: number;
  displayValue: string;
};

@Component({
  name: 'GroupWalletItem',
  components: { Chart },
})
export default class GroupWalletItem extends BaseVue {
  @Prop({ required: true })
  wallet!: WalletDTO;

  @Prop({ required: true })
  public readonly loadingBalance!: boolean;

  barColors = ['#008DFF', '#B8DCFF', '#86C7FF', '#E1F2FF'];
  colors = [
    '#3B82F6',
    '#60A5FA',
    '#93C5FD',
    '#BFDBFE',
    '#DBEAFE',
    '#EFF6FF',
    '#1E3A8A',
    '#1E40AF',
    '#1D4ED8',
    '#2563EB',
  ];

  tokenData: any = null;

  mounted() {
    this.drawBalanceChart();
  }

  drawBalanceChart() {
    const balances: any[] = this.wallet.balance?.balances ?? [];
    this.tokenData = {
      type: 'doughnut',
      data: {
        labels: balances.map((account) => account.coin),
        datasets: [
          {
            label: 'My First Dataset',
            data: balances.map((account) => account.fiatValue?.value ?? 0),
            backgroundColor: balances.map((_: any, index: number) => this.colors[index % this.colors.length]),
            hoverOffset: 4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        devicePixelRatio: 1,
      },
    };
  }

  get currentOrganization(): Org | undefined {
    return this.$store.state.currentOrg;
  }

  get topTokens() {
    if (!this.wallet.balance?.balances) return [];
    let balances: Balance[] = (this.wallet.balance.balances ?? []).map((e) => {
      return {
        coin: e?.coin ?? '',
        value: e?.fiatValue?.value ?? 0,
        displayValue: e?.displayValue ?? '0',
      };
    });
    balances.sort((a, b) => b.value - a.value);
    if (balances.length > 4) {
      const otherBalances = balances.slice(3);
      const totalOtherBalances = otherBalances.reduce((t, cur) => t + cur.value, 0);
      balances = balances.slice(0, 3).filter((e) => e.value);
      balances.push({
        coin: 'Other',
        value: totalOtherBalances,
        displayValue: totalOtherBalances.toString(),
      });
    }
    return balances;
  }

  get tokens() {
    const balances: any[] = this.wallet.balance?.balances ?? [];
    balances.sort((a, b) => Number(b.fiatValue?.value ?? 0) - Number(a.fiatValue?.value ?? 0));
    return balances.map((account) => account.coin);
  }

  expand() {
    this.wallet.expanded = !this.wallet.expanded;
  }

  @Watch('wallet')
  balanceUpdated() {
    this.drawBalanceChart();
  }
}
